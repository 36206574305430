@import "../../variables.scss";

$search-modal-width: 705px;

* {
  box-sizing: border-box;
}

/* SearchModal.tsx styles */
.search-modal-backdrop {
  display: flex;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: $backgrounds-search;
  z-index: 1000;
  align-items: center;
  justify-content: center;
}

.search-modal-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  height: 520px;
  border-radius: 8px;
  background-color: $background-main;
  box-shadow: $dropshadow-default;
  font-size: $font-size-small;
  color: $text-secondary;
}

/* Search Modal Header Styles */
.search-modal-header-container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  align-self: stretch;
}

.zen-form-field {
  flex-grow: 1;
}

/* clears the default ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the default ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.search-modal-icon-close-container {
  display: none;
}

.modal-search-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-result-match {
  font-weight: 500;
  text-decoration-line: underline;
}

.search-results-container {
  overflow: auto;
  align-self: flex-start;
  width: $search-modal-width;
}

.tab-container {
  display: flex;
  justify-content: center;
  max-height: 100%;
  width: $search-modal-width;
  overflow: scroll;

  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-inline-start: 8px;
    margin-right: 16px;

    a {
      padding-bottom: 8px;

      &:hover,
      &:focus {
        background: $backgrounds-search-hover;
        border-radius: 8px;
      }
    }
  }

  a {
    color: inherit;
  }

  .breadCrumbContainer {
    color: $text-secondary;
  }

  .guide-title {
    padding-left: 3px;
  }

  .guide-icon {
    padding-left: 3px;
  }

  .search-results-snippet {
    padding-inline-start: 42px;
    overflow-wrap: anywhere;
  }
}

.result-item-title {
  font-size: $font-size-medium;
}

.result-listing-header-container {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 12px;
}

.empty-search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.tab-search-not-found {
  text-align: center;

  img {
    border: none;
  }
}

.modal-search-icon.hidden {
  display: none;
}

.search-result-link {
  text-decoration: none;
}

/* NOTE: The Geotab tab component renders the content of the tabs as below and so far, the only way to properly add custom styling is by targeting the specific class and data-state or it will apply styling to all tab components horizontally
  <div data-state="active" data-orientation="horizontal" role="tabpanel" aria-labelledby="radix-:r9h:-trigger-All" id="All" tabindex="0" class="Tabs-module_TabsContent__wDBJ4" style=""><div class="tab-container"><div class="tab-search-icon"></div></div></div>*/

.Tabs-module_TabsRoot__tfB8h {
  background-color: $background-main;
  border-radius: 8px;
}

.Tabs-module_tabsWrapper__vprxe {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  margin: 0px;
  border-bottom: 1px solid $backgrounds-content-1;
  background-color: $background-main;
}

.Tabs-module_tabsContainer__LLjH7 {
  margin: 0px 16px;
  border-bottom: 1px solid $backgrounds-content-1;
  overflow-x: unset;
}

.search-modal-tabs-container {
  .zen-tabs {
    border-bottom: 1px solid $borders-search-tab;
    overflow-x: unset;
    white-space: nowrap;
    display: flex;
    align-items: flex-start;
    padding: 0px 24px;
    height: 36px;
  }

  .zen-tab-item__content-icon {
    width: 16px;
  }

  .zen-tab-bar-content-wrapper {
    display: flex;
    align-items: center;
    height: 400px;
  }

  .zen-tab-bar-content-wrapper--hidden {
    display: none;
  }
}

.Tabs-module_TabsContent__wDBJ4:not([data-state="active"]) {
  display: none;
}

.Tabs-module_TabsContent__wDBJ4[data-state="active"] {
  display: flex;
  padding: 8px;
  height: 400px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background-color: $background-main;

  &.tab-container .has.results {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    height: auto;
  }
}

.Tabs-module_tabContainer__cAmFN button[aria-selected="true"] {
  color: var(--text-nav-highlight);
  border-bottom: 4px solid var(--text-nav-highlight);
}

@media screen and (max-width: $mobile-max-width) {
  .Tabs-module_TabsList__0bZ84 {
    width: 100%;
  }

  .Tabs-module_tabContainer__cAmFN {
    flex: 0 0 33%;
  }

  .Tabs-module_tabContainer__cAmFN button {
    width: 100%;
  }

  .search-modal-backdrop {
    background-color: transparent;
  }

  .search-modal-tabs-container {
    width: 100%;

    .zen-tab-item {
      flex: 0 0 33%;
      display: flex;
      justify-content: center;
      flex: 1;
    }

    .zen-tab-bar-content {
      padding: 0px 8px;
    }

    .tab-container {
      width: 100%;
      padding: 0px 8px;
    }

    .zen-tab-bar-content-wrapper {
      height: calc(100vh - 110px);
      margin-left: auto;
      margin-right: auto;
    }
  }

  .search-modal-container {
    width: 100%;
    height: 100%;
  }

  .search-modal-header-container {
    flex-direction: row;
  }

  .search-modal-input-field-container {
    flex-grow: 1;
  }

  .search-modal-icon-close-container {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $background-main;
    border: none;
    cursor: pointer;
  }
}