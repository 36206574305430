@import "../../variables";

.pageTitle {
    color: $page-title-text-primary;
    padding: 16px 24px;
    gap: 8px;
    display: flex;
    flex-direction: column;

    &__titleText{
        font-weight: 300;
        font-size: 28px;
        line-height: 36px;
        margin: 0px;
    }

    &__subContainer {
        display: flex;
        align-items: center;
        gap: 6px;
    }
}

.page-title-container {
    background-color: $background-main;
    border-bottom: 1px solid $border-color;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 24px;
    
    button{
        min-width: 100px;
        justify-content: center;
    }
}

@media screen and (max-width: $mobile-max-width) {
    .pageTitle { 
        padding: 0px;
        gap: 0px;
    }

    .page-title-container {
        flex-direction: column;
        align-items: flex-start;
        padding: 8px 16px 16px 16px;
        gap: 8px; 

        button {
            width: 100%;
        }
    }
    
    .page-title-button-container {
        width: 100%;
    }
}