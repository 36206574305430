@import "../../variables.scss";

.pageContent {
    padding: 32px 24px;
    display: inline-block;
    width: 100%;

    &__landing {
        @extend .pageContent;
        justify-content: center;
    }

    &__container {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        max-height: 100vh;
        width: 100vw;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__subContainer {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        flex: 1;
    }

    &__scrollableArea {
        float: inline-start;
        width: 75%;
    }
}

@media screen and (max-width: $mobile-max-width) {
    .pageContent {
        padding: 8px 16px 24px 16px;

        &__scrollableArea {
            width: 100%;
            gap: 16px !important;
        }

        &__landing {
            padding: 0px;
        }
    }
}
