$page-title-background: #F8F9FC;
$page-title-text-primary: #202328;
$page-title-text-secondary: #575757;

$table-of-contents-heading-text: #1F2833;
$table-of-contents-hover-background: #F2F5F7;

$font-size-x-large: 28px;
$font-size-large: 20px;
$font-size-medium: 14px;
$font-size-small: 12px;

$backgrounds-content-0: #F9FAFB;
$backgrounds-search: #0000001a;
$backgrounds-search-hover: #1f283312;
$borders-search-tab: #f2f5f7;
$borders-form-field-hover: #748faa;
$header-active-button-bg: #748FAA;
$header-hover-button-bg: #E6EBEF;
$text-landing-page: #1E1E1E;
$text-reverse-primary: #fff;
$text-placeholder: #8da4b9;
$text-primary: #1f2833;
$text-secondary: #4E677E;
$text-hyperlink: #005AA8;

$button-bg: #FFFFFF;
$button-bg--focus: #F9FAFB;
$button-outline: #C0CCD8;
$button-outline--focus: #0078D3;

$border-color: #c0ccd8;
$border-radius: 8px;

$code-bg: #f8f9fa;
$code-color: #bd4147;

$side-note-bg: #EBF6FF;
$side-note-color: #25477B;

$actions-primary-active: #002D4F;
$actions-secondary-default: #4e677e;

$background-main: #fff;
$backgrounds-content-1: #f2f5f7;
$backgrounds-content-2: #D8DEE9;

$borders-general: #c0ccd8;

$dropshadow-default: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);

$dark-mode-content-color: #ffffffb3;
$dark-mode-content-color-active: #ffffff;
$dark-mode-content-color-hover-focus: #202124;
$light-mode-content-color-active: #ffffff;

$scrollbar-thumb-color: #c1c1c1;
$mobile-max-width: 960px;