.navbarContainer {
    z-index: 3;

    a *,
    button * {
        box-sizing: unset;
    }
}

// This is for the pills that are in the pop-out menu from the SideNavigation component, the pop-out menus are not within the navbarContainer
div[class^="Pill-module_base"] {
    box-sizing: unset;
}

// Apply cursor pointer to the pill container to be more consistent with the other menu items
span[class^="Pill-module_pillContainer"] {
    cursor: pointer;
}