@import "../../variables.scss";

.footer-container {
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  background: $actions-primary-active;
  margin-top: auto;
  bottom: 0;
  z-index: 10;
}

.footer-container__left, .link-main-container {
  display: flex;
  align-items: center;
  gap: 24px;

  .link-container {
    display: flex;
    gap: 24px;

    a {
      color: $backgrounds-content-2;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-decoration: none;
    }

    .footer-link-divider {
      display: flex;
    }
  }
}
.footer-container__right {
  width: 205px;
  flex-shrink: 0;
  text-align: right;

  .footer-copyright {
    color: $backgrounds-content-2;
    font-size: $font-size-small;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.32px;
  }
}

@media (max-width: $mobile-max-width) {

  .footer-container {
    align-items: stretch;
    flex-direction: column;
    gap: 16px;
  
  }

  .link-main-container {
    align-items: stretch;
    flex-direction: column;
    gap: 8px;
  }
  .footer-container__left {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .footer-container__right {
    text-align: left;
  }

  .link-container {
    // Remove footer divider
    .footer-link-divider {
      display: none !important;
    }
  }
}
