@import "../../variables.scss";

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    background-color: $background-main;
    flex-shrink: 0;
    height: 64px;
    gap: 10px;
    padding: 0 24px;
    position: sticky;
    top: 0;
    z-index: 2;

    &.is-pinned {
        box-shadow: $dropshadow-default;
    }

    &.landing-page-border {
        border-bottom: 1px solid $border-color;
    }
}

.header-container__left {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 40px;

    .header-menu-tabs {
        display: flex;
        gap: 16px;
    }

    .header-menu-tab {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        width: 80px;
        height: 32px;

        &:hover {
            background-color: $header-hover-button-bg;
        }

        a {
            padding: 10px;
            color: $text-secondary;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            text-decoration: none;
            font-size: $font-size-small;
            width: 100%;
            text-align: center;
        }

        &.active-button {
            background-color: $header-active-button-bg;

            a {
                color: $text-reverse-primary;
            }
        }
    }
}

.header__container__right {
    display: flex;
    gap: 16px;

    .header-ask-developer-button {
        align-items: center;
        display: flex;
        gap: 6px;
    }

    .header-search-button {
        width: 163px;
        align-items: center;
        display: flex;
        gap: 6px;
    }
}

@media screen and (max-width: $mobile-max-width) {
    .header-container {
        padding: 16px;

        .header-container__menu-select {
            width: 100%;
        }
    }

    header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid var(--Borders-General, $border-color);

        .header__container__right {

            .zen-button {
                background: transparent;
                background-color: transparent;
                border: none !important;
            }

            .zen-icon {
                width: 16px;
                height: 16px;
            }

            .header-search-button {
                width: fit-content !important;
                justify-content: center;
                font-size: 40px;
            }
        }


    }
}