@import "../../variables";

.dataTypeToolTip__container {
    display: flex;
    align-items: center;
    gap: 2px;

    & svg {
        height: 14px;
        width: 14px;
    }
}

.methods__method-title,
.objects__object-title {
    align-items: center;
    gap: 8px;
    font-size: $font-size-large;
    color: $text-primary;
    font-weight: 400;
    line-height: 24px;
    margin: 0px;
}

.methods__view-button,
.objects__view-button {
    display: flex;
    margin-left: auto;
}

.pageContent__scrollableArea {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.method-object-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    scroll-margin-top: 80px;
}

.detailsContent {
    h2 {
        margin-bottom: 0px;
        color: $text-primary;
    }
}

.object-property-container,
.method-parameter-container {
    flex-direction: column;
    display: flex;
    border: 1px solid $border-color;
    border-radius: 8px;
    margin: 16px 0px;
    overflow: hidden;
}

.object-property-type-labels,
.method-parameter-type-labels {
    display: flex;
    gap: 8px;
}

.object-property-description-container, 
.method-parameter-description-container {
    gap: 8px;
    padding: 20px 16px 20px 16px;
}

.object-property-header-container,
.method-parameter-header-container,
.object-pagination-header-container {
    h2 {
        margin: 0
    }

    display: flex;
    gap: 8px;
    padding: 20px 16px 20px 16px;
    min-width: 250px;
    background: #F9FAFB;
}

.object-pagination-container{
    flex-direction: row;
    display: flex;
    border: 1px solid $border-color;
    border-radius: 8px;
    margin: 16px 0px;
    overflow: hidden;
}

.object-pagination-description-container {
    p{
        margin: 0;
    }
    gap: 8px;
    padding: 20px 16px 20px 16px;
}

@media screen and (max-width: $mobile-max-width) {
    .object-property-header-container,
    .method-parameter-header-container {
        width: auto;
        overflow-x: auto;
    }
    .object-pagination-container {
        flex-direction: column;
    }

}