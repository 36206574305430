@import "../../variables";

.breadCrumbContainer {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $page-title-text-secondary;
    display: flex;
    margin-block-end: 0px;
    gap: 4px;
    flex-wrap: wrap;

    &__atom {
        display: flex;
        gap: 4px;
    }

    &__chevron {
        width: 16px;
        height: 16px;
        padding: 0;
    }
}