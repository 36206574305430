@import '../../variables';

.tableOfContents {
    background-color: $page-title-background;
    border-radius: 8px;
    float: inline-end;
    width: 20%;
    max-width: 288px;
    height: fit-content;
    margin: 0;
    position: sticky;
    top: 15px;
    list-style-type: none;
    padding: 0 0 8px 0;
    max-height: calc(100vh - 260px);
    overflow: auto;

    &.is-pinned::before {
        box-shadow: $dropshadow-default;
    }

    &::before {
        content: 'Table of contents';
        display: block;
        padding: 16px 16px 8px 16px;
        font-weight: 400;
        font-size: 18px;
        color: $table-of-contents-heading-text;
        position: sticky;
        top: 0;
        background-color: $page-title-background;
        cursor: default;
    }

    & li {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;

        &:not(:last-of-type) {
            margin-bottom: 4px;
        }

        & a {
            padding: 8px 12px;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: $text-secondary;
            text-decoration: none;
            display: block;
            overflow-wrap: anywhere;
        }

        &:hover,
        &:focus,
        &:focus-within,
        &:active,
        &.tableOfContents__item--active {
            background-color: $table-of-contents-hover-background;
            border-left: 4px solid $button-outline--focus;
            cursor: pointer;
        }
    }

    &__mobile {
        display: block;
        position: sticky;
        top: 0;
        z-index: 1;
        width: 100%;
        background-color: $background-main;
        padding: 16px 16px 8px 16px;
    }
}