details>summary {
    list-style: none;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-block-end: 16px;
    display: flex;
    scroll-margin-top: 80px;

    &:hover {
        cursor: pointer;
    }
}

summary::-webkit-details-marker {
    display: none;
}

details>summary>svg {
    margin-left: 5px;
}

details {
    margin-block-start: 16px;
}