.informational-box {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 4px;
    background: #EBF6FF;
    margin: 16px 0;
    color: #25477B;

    .icon-container {
        display: flex;
        align-items: center;
    }

    p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.32px;
    }
}